$(document).ready(function(){
  $('.news-section .news-list').slick({
    dots: true,
    infinite: false,
    speed: 300,
    slidesToShow: 4,
    slidesToScroll: 4,
    arrows: true,
    nextArrow: $('#next'),
    prevArrow: $('#previous'),
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  });
  if ($('.trusted-wrap .slick-slide').length > 1) {
     var $foo = true;
  }else {
    var $foo = false;
  }
  $('.trusted-wrap').slick({
    dots: $foo,
    infinite: false,
    speed: 300,
    slidesToShow: 6,
    slidesToScroll: 6,
    arrows: true,
    nextArrow: '<div id="previous-trusted" class="slick-arrow-div-left"><i class="fa fa-angle-left"></i></div>',
    prevArrow: '<div id="next-trusted" class="slick-arrow-div-right"><i class="fa fa-angle-right"></i></div>',
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  });

  $('.products-section .product-list').slick({
    dots: true,
    infinite: false,
    speed: 300,
    slidesToShow: 4,
    slidesToScroll: 4,
    arrows: true,
    nextArrow: '<div id="previous-product" class="slick-arrow-div-left"><i class="fa fa-angle-left"></i></div>',
    prevArrow: '<div id="next-product" class="slick-arrow-div-right"><i class="fa fa-angle-right"></i></div>',
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  });
  $( ".trusted-wrap ul.slick-dots" ).before( $( '<div class="slick-brands-arrow arw-prev-brand"><i class="fas fa-angle-double-left"></i></div>' ) );
  $( ".trusted-wrap ul.slick-dots" ).after( $( '<div class="slick-brands-arrow arw-next-brand"><i class="fas fa-angle-double-right"></i></div>' ) );
  $( ".products-section .product-list ul.slick-dots" ).before( $( '<div class="slick-product-arrow arw-prev-prod"><i class="fas fa-angle-double-left"></i></div>' ) );
  $( ".products-section .product-list ul.slick-dots" ).after( $( '<div class="slick-product-arrow arw-next-prod"><i class="fas fa-angle-double-right"></i></div>' ) );
  $( ".news-section .news-list ul.slick-dots" ).before( $( '<div class="slick-news-arrow arw-prev-news"><i class="fas fa-angle-double-left"></i></div>' ) );
  $( ".news-section .news-list ul.slick-dots" ).after( $( '<div class="slick-news-arrow arw-next-news"><i class="fas fa-angle-double-right"></i></div>' ) );
  function _stickySidebar(){
    if (Modernizr.mq("(min-width: 992px)")) {
      $(".fixed-sidebar").sticky({
        topSpacing: 0,
        bottomSpacing: $(".footer").outerHeight() + 20,
      });
    } else {
      $(".fixed-sidebar").unstick();
    }
  }
  $(window).load(function(){
    var screensize = $(window).outerHeight();
    var navheader = $('aside header').outerHeight();
    var navsearch = $('aside .mobile-search-engine').outerHeight();
    var navfooter = $('aside .sidepanel-footer').outerHeight();
    var totalmaxheight = screensize - (navheader + navsearch + navfooter);
    $('aside nav.nav').css('maxHeight', totalmaxheight);
    $(document).on('click', '.catalog-page-layout .mobile-dl-btn', function(){
        var dlfilename = $(this).data('filetitle');
        var dlfileurl = $(this).data('fileurl');
        $('#catalogModal .application-form-wrapper .form-title h3').text(dlfilename);
        $("#catalogModal .application-form-wrapper .command-wrapper a").prop("href", dlfileurl);
    });
    $(document).on('click', '.catalog-page-layout .download-file-wrapper .file .dl-btn', function(){
        var dlfilename = $(this).data('filetitle');
        var dlfileurl = $(this).data('fileurl');
        $('#catalogModal .application-form-wrapper .form-title h3').text(dlfilename);
        $("#catalogModal .application-form-wrapper .command-wrapper a").prop("href", dlfileurl);
    });
    _stickySidebar();
    $('a[data-rel^=lightcase]').lightcase({
      forceHeight: true,
      forceWidth: true,
      inline: {
      height: '1000',
      width: '1000'
      },
      maxWidth: '1000',
      maxHeight: '900'
    });
    var $totalresult = $('#totalfound').data('totalresult');
    var $counterbrand = $('#totalfound').data('counterbrand');
    if ($counterbrand == 0) {
      $('.brand-result').css('display', 'none');
    }
    $('.countresult-wrapper .countresult p span.count-value').text($totalresult);
    $('span.resumeFile input#attachresume').change(function(e){
      var fileName = e.target.files[0].name;
      $('#resumeattach .resumewrap .file-detail-info').text(fileName);
    });
    $('span.uploadImage1 input#uploadimageone').change(function(e){
      var fileName = e.target.files[0].name;
      $('.file-name-up').text(fileName);
    });
    $('.arw-next-brand').click(function(){
      $('#next-trusted').click();
    });
    $('.arw-prev-brand').click(function(){
      $('#previous-trusted').click();
    });
    $('.arw-next-news').click(function(){
      $('#next').click();
    });
    $('.arw-prev-news').click(function(){
      $('#previous').click();
    });
    $('.arw-prev-prod').click(function(){
      $('#next-product').click();
    });
    $('.arw-next-prod').click(function(){
      $('#previous-product').click();
    });
    $('.dropdown-cat').hover(function(){
      $('.product-drop-down').toggleClass('showdrop');
    });
    $('.product-drop-down').hover(function(){
      if( $(this).hasClass('showdrop') ) {
        $(this).removeClass('showdrop');
      } else {
        $(this).addClass('showdrop');
      }
      // $(this).toggleClass('showdrop');
    });
     $('.product-gallery .slick-current img').elevateZoom({
        zoomType        : "lens",
        lensShape : "round",
        lensSize    : 200
      });
     $('.product-gallery').on('beforeChange', function(event, slick, currentSlide, nextSlide){
       var img = $(slick.$slides[nextSlide]).find("img");
       $('.zoomWindowContainer,.zoomContainer').remove();
       $(img).elevateZoom({
        zoomType        : "lens",
        lensShape : "round",
        lensSize    : 200
      });
    });
    $('.brand-filter-btn').click(function(){
      $('.brandfilter-wrap-side').addClass('active-filter');
      $('#quitbrand-filter').click(function(){
        $('.brandfilter-wrap-side').removeClass('active-filter');
      });
    });
    $('.product-filter-btn').click(function(){
      $('.productfilter-wrap-side').addClass('active-filter');
      $('#quitprod-filter').click(function(){
        $('.productfilter-wrap-side').removeClass('active-filter');
      });
    });
    // $('#product-results .post-wrapper .post-new-wrap .wrap .post-allcontent .post-title p').matchHeight();
    // $('#product-results .post-wrapper .post-new-wrap .wrap .post-allcontent').matchHeight();
    $('#product-results .post-wrapper .post-new-wrap .wrap .post-allcontent .post-img').matchHeight();
    $('#product-results .post-wrapper .post-new-wrap .wrap').matchHeight();
    $('.front-page-layout .news-section .news-list .blog-item .post-content').matchHeight();
    $('.products-section .product-list .blog-item .post-content h3').matchHeight();
    $('.post-wrapper .post-item .post-allcontent .post-title').matchHeight();
    $('.post-wrapper .post-item .post-allcontent').matchHeight();
    $('.front-page-layout .products-section .product-list .blog-item .post-content').matchHeight();
    $('#imageattachone').click(function(){
      $('#uploadimageone').click();
    });
    $('#resumeattach').click(function(){
      $('#attachresume').click();
    });
    $(document).on('click', '.position-item .position-title', function () {
      $(this).parent().toggleClass('active-id').siblings().removeClass('active-id');
    });
    $(document).on('click', '.career-btn', function(){
      var $job_title = $(this).data('positiontitle');
      $('.career-modal').modal('hide');
      $('.form-wrapper span.txtCareer input.position-title').val($job_title);
      $('.application-form-wrapper .form-title h3').text($job_title);
    });
    $(document).on('click', '.product-inquire-btn', function(){
      var $featuredimg = $(this).data('featuredimg');
      var $product_title = $(this).data('producttitle');
      console.log($featuredimg);
      console.log($product_title);
      $('.product-modal').modal('hide');
      $('.product-modal .product-information-wrapper .feature-img img').attr('src',$featuredimg);
      $('.product-modal .product-information-wrapper .product-title p').text($product_title);
    });
		// INITIATIONS
		AOS.init();
		$('.main-banner-wrapper').on('init', function(e, slick) {
        var $firstAnimatingElements = $('div.slick-slide:first-child').find('[data-animation]');
        doAnimations($firstAnimatingElements);
        });
        $('.main-banner-wrapper').on('beforeChange', function(e, slick, currentSlide, nextSlide) {
      var $animatingElements = $('div.slick-slide[data-slick-index="' + nextSlide + '"]').find('[data-animation]');
      doAnimations($animatingElements);
    });
		$('.main-banner-wrapper').slick({
      autoplay: false,
      dots: true,
      infinite: true,
      speed: 500,
      fade: true,
      arrows: true,
      // nextArrow: $('#next'),
      // prevArrow: $('#previous'),
      nextArrow: '<div class="slick-arrow-div-right"><i class="fa fa-angle-right"></i></div>',
      prevArrow: '<div class="slick-arrow-div-left"><i class="fa fa-angle-left"></i></div>',
      cssEase: 'linear',
      adaptiveHeight: false,
      slidesToShow: 1,
        slidesToScroll: 1,
    });
		// VARIABLES
		var $pageHeight = $(window).height(),
		$pageWidth = $(window).width(),
		$navHeight = $('header.main-header').outerHeight(),
		$footerHeight = $('footer.footer').outerHeight(),
		$mainWrapper = $('.wrapper-holder');


		// CUSTOM FUNCTIONS
		carouselAnimation();
		tableClass();
		mobileLayout();
		wrapperHolder( $pageHeight, $navHeight, $footerHeight );
		cf7formsubmit();

		$('.loader-overlay').fadeOut(200);

	});

	$(window).on('resize', function(){
    $('.post-wrapper .post-item .post-allcontent .post-title').matchHeight();
		// VARIABLES
		var $pageHeight = $(window).height(),
		$pageWidth = $(window).width(),
		$navHeight = $('header.main-header').outerHeight(),
		$footerHeight = $('footer.footer').outerHeight(),
		$mainWrapper = $('.wrapper-holder');

		wrapperHolder( $pageHeight, $navHeight, $footerHeight );
	});

});

/* MODERNIZR LAYOUT - This serves as the the media query inside the Javascript */
if( Modernizr.mq('(min-width: 1200px)') ) {
  $('.product-gallery').slick({
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    centerMode: false,
    fade: true,
    infinite: false,
    autoplay: false,
    asNavFor: '.gallerythumnail-wrap'
  });
  $('.gallerythumnail-wrap').slick({
    slidesToShow: 5,
    autoplay: false,
    slidesToScroll: 1,
    asNavFor: '.product-gallery',
    centerMode: false,
    infinite: false,
    vertical: true,
    arrows: false,
    focusOnSelect: true,
    swipe: true,
    swipeToSlide: true,
    verticalSwiping: true
  });
}
else if( Modernizr.mq('(min-width: 992px)') && Modernizr.mq('(max-width: 1199px)') ) {
    $('.product-gallery').slick({
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: false,
      centerMode: false,
      fade: true,
      infinite: false,
      autoplay: false,
      asNavFor: '.gallerythumnail-wrap'
    });
    $('.gallerythumnail-wrap').slick({
      slidesToShow: 5,
      autoplay: false,
      slidesToScroll: 1,
      asNavFor: '.product-gallery',
      centerMode: false,
      infinite: false,
      vertical: true,
      arrows: false,
      focusOnSelect: true,
      swipe: true,
      swipeToSlide: true,
      verticalSwiping: true
    });
}
else if( Modernizr.mq('(max-width: 991px)') && Modernizr.mq('(min-width: 768px)')){
  $('.product-gallery').slick({
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: false,
      centerMode: false,
      fade: true,
      infinite: false,
      autoplay: false,
      asNavFor: '.gallerythumnail-wrap'
  });
  $('.gallerythumnail-wrap').slick({
    slidesToShow: 5,
    autoplay: false,
    slidesToScroll: 1,
    asNavFor: '.product-gallery',
    centerMode: false,
    infinite: false,
    vertical: true,
    arrows: false,
    focusOnSelect: true,
    swipe: true,
    swipeToSlide: true,
    verticalSwiping: true
  });
}
else{
  $('.product-gallery').slick({
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    centerMode: false,
    fade: true,
    infinite: false,
    autoplay: false,
    asNavFor: '.gallerythumnail-wrap'
  });
  $('.gallerythumnail-wrap').slick({
    slidesToShow: 5,
    autoplay: false,
    slidesToScroll: 1,
    asNavFor: '.product-gallery',
    centerMode: false,
    infinite: false,
    vertical: false,
    arrows: false,
    focusOnSelect: true,
    swipe: true,
    swipeToSlide: true,
    verticalSwiping: true
  });
}

// FUNCTION LISTS
/*
* Method smooth scrolls to given anchor point
*/
function smoothScrollTo(anchor) {
	var duration = 400; //time (milliseconds) it takes to reach anchor point
	var targetY = $(anchor).offset().top;
	$("html, body").animate({
		"scrollTop" : targetY
	}, duration, 'easeInOutCubic');
}
function doAnimations(elements) {
  var animationEndEvents = 'webkitAnimationEnd mozAnimationEnd MSAnimationEnd oanimationend animationend';
  elements.each(function() {
    var $this = $(this);
    var $animationDelay = $this.data('delay');
    var $animationType = 'animated ' + $this.data('animation');
    $this.css({
      'animation-delay': $animationDelay,
      '-webkit-animation-delay': $animationDelay
    });
    $this.addClass($animationType).one(animationEndEvents, function() {
      $this.removeClass($animationType);
    });
  });
}

function carouselAnimation(){
	/* Demo Scripts for Bootstrap Carousel and Animate.css article
	* on SitePoint by Maria Antonietta Perna
	*/
  //Function to animate slider captions 
  function doAnimations( elems ) {
    //Cache the animationend event in a variable
    var animEndEv = 'webkitAnimationEnd animationend';
    
    elems.each(function () {
      var $this = $(this),
        $animationType = $this.data('animation');
      $this.addClass($animationType).one(animEndEv, function () {
        $this.removeClass($animationType);
      });
    });
  }
  
  //Variables on page load 
  var $myCarousel = $('.carousel'),
    $firstAnimatingElems = $myCarousel.find('.item:first').find("[data-animation ^= 'animated']");
      
  //Initialize carousel 
  $myCarousel.carousel();
  
  //Animate captions in first slide on page load 
  doAnimations($firstAnimatingElems);
  
  //Pause carousel  
  $myCarousel.carousel('pause');
  
  
  //Other slides to be animated on carousel slide event 
  $myCarousel.on('slide.bs.carousel', function (e) {
    var $animatingElems = $(e.relatedTarget).find("[data-animation ^= 'animated']");
    doAnimations($animatingElems);
  });

  $('.carousel').carousel();
}

function tableClass(){
	var $tables = $(document).find('table');
	if( $tables ) {
		$tables.wrap('<div class="table-responsive"></div>');
		$tables.addClass('table');
	}
}
function wrapperHolder( $pageHeight, $navHeight, $footerHeight ){
	$('.wrapper-holder').css({
		'min-height': $pageHeight - $navHeight,
		'margin-top': $navHeight,
		'padding-bottom': $footerHeight
	});
  $('.error-page, .thankyou-page-layout').css({
    'height': $pageHeight - $navHeight - $footerHeight
  });
	$('.main-layout').css({
		'min-height': $pageHeight - ( $navHeight + $footerHeight ),
	});
}
function mobileLayout(){
	// MOBILE MENU LAYOUT
	$('.sidepanel .menu > .menu-item-has-children').addClass('dropdown row-size');
	$('.sidepanel .menu > .menu-item-has-children > a').each(function(){
		var $curr = $(this);
		$curr.addClass('column-top nav-title');
		$('<span class="fa fa-plus dropdown-toggle nav-control column-top" data-toggle="dropdown" style="min-height: '+ $curr.outerHeight() +'px;"></span>').insertAfter( $curr );			
	});
	$('.sidepanel .menu > .menu-item-has-children > .sub-menu').addClass('dropdown-menu');
	// MOBILE MENU
	if(!$('.sidepanel').hasClass('sidepanel-out')){
		$('.close-sidemenu').hide();
	}
	$('.mobile-menu-btn').click(function(){
		$('.sidepanel').toggleClass("sidepanel-out" );
		$(this).toggleClass('toggle-mobile-menu');
		if(!$('.sidepanel').hasClass('sidepanel-out')){
			$('.close-sidemenu').hide();
		} else {
			$('.close-sidemenu').show();
		}
	});
	$('.close-sidemenu').click(function(){
		$('.sidepanel').toggleClass("sidepanel-out");
		$(this).hide();
	});
	$('.sidepanel li a').click(function(){
		$(this).find('.fa-plus').toggleClass('fa-minus');
	});

	// BACK TO TOP
	$('.back-to-top').hide(); // HIDE ON FIRST LOAD
	$(window).scroll(function () {
		if ($(this).scrollTop() > 100) {
			$('.back-to-top').fadeIn();
		} else {
			$('.back-to-top').fadeOut();
		}
	});
	$('.back-to-top a').click(function () {
		$('body,html').animate({
			scrollTop: 0
		}, 800);
		return false;
	});

	$(document).on('click', '.mobile-menu-btn', function(){
		$('.menu-button').toggleClass('active');
	});
}

function cf7formsubmit(){
	var sitelink = $('.usd').data('usdacct');
	document.addEventListener( 'wpcf7mailsent', function( event ) {
	  location = sitelink + '/thank-you/';
	}, false );
  document.addEventListener( 'wpcf7invalid', function( event ) {
    var $alertresume = $('span.resumeFile input#attachresume.wpcf7-not-valid');
    if ($alertresume.length) {
      $('#resumeattach').css('background-color', '#fde0e0');
      $('#resumeattach .resumewrap .file-detail-info').css('opacity', 1);
    }else{
      $('#resumeattach').css('background-color', '#fff');
      $('#resumeattach .resumewrap .file-detail-info').css('opacity', 1);
    }
  }, false );
}

function locations($country, $state, $city){
	// Sample Field
	// "select.csCountryOption", "select.csStateOption", "select.csCityOption"
	filter_country_state_city($country, $state, $city);
}

function objLightcase(){
	$('a[data-rel^=lightcase]').lightcase();
}

function responsiveSlick(){
	$('.slick-responsive').each(function(){
		var breaks = $(this).data('breakpoints').split('|'),
		breakArr = [],
		arrowsval;
		$.each(breaks, function(i, items){
			breakArr.push({
				breakpoint: parseInt(items.split(',')[0]),
	      settings: {
	        slidesToShow: parseInt(items.split(',')[1])
	      }
			});
		});
		if( $(this).data('arrows') ) {
			arrowsval = true;
		} else {
			arrowsval = false;
		}
		$(this).slick({
		  dots: false,
		  infinite: true,
		  speed: 300,
		  autoplay: true,
		  arrows: arrowsval,
		  slidesToShow: $(this).data('defaultview'),
		  slidesToScroll: 1,
		  adaptiveHeight: true,
		  asNavFor: $(this).data('thumb'),
		  responsive : breakArr,
		  prevArrow : '<button type="button" class="slick-prev slick-controller fa fa-angle-left"></button>',
		  nextArrow : '<button type="button" class="slick-next slick-controller fa fa-angle-right"></button>',
		});
	});
}